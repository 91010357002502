<template>
  <div id="cgu_hub">
    <CguHubDe v-if="country === 'de'" />
    <CguHubFr v-else />
  </div>
</template>

<script>
import CguHubFr from "@/components/legal/hub/CguHubFr.vue";
import CguHubDe from "@/components/legal/hub/CguHubDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les CGU de Hub dans la bonne langue.
 */
export default {
  name: "CguHub",
  components: {
    CguHubFr,
    CguHubDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
